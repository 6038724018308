import React from 'react';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';

import configureStore, { history } from './store';
import './App.less';

import Root from './containers/Root';
import BackErrorNotification from 'components/BackErrorNotification'

// Setup icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(
  fas,
  fab,
  fal,
  fad,
);


export const store = configureStore();

const App = () => {
  const [api, contextHolder] = notification.useNotification(); // eslint-disable-line no-unused-vars
  return (
    <Provider store={store}>
      <IntlProvider>
        {contextHolder}
        <BackErrorNotification />
        <Router history={history}>
          <Switch>
            <Route path="/" component={Root}/>
          </Switch>
        </Router>
      </IntlProvider>
    </Provider>
  )}

export default App;

