import React from 'react'
import { Layout } from 'antd';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import AppHeader from 'components/AppHeader';

import './style.less';

const { Header } = Layout;

const zIndex = 999;
const height = '120px';
const width = '100%';

const AppFramework = ({
  children,
  errorMessage,
  intl
}) => {
  return (
  <React.Fragment>
    <Header
      style={{
        position: 'fixed',
        zIndex,
        width,
        height,
      }}
    >
      <AppHeader/>
    </Header>
    <div
      style={{
        width,
        height,
      }}
    ></div>
    <Layout className='main-layout'>
      {errorMessage ? (
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: `error_boundary_title_message` })}
            buttonLabel={intl.formatMessage({ id: `error_boundary_reload_button` })}
            descriptionErrorMessage={errorMessage}
            customErrorImage="/images/error_image.png"
          />
        }>
          {children}
        </Sentry.ErrorBoundary>
      ) : (children)}
    </Layout>
  </React.Fragment>
)};

export default injectIntl(AppFramework);
