import React, {
  useMemo,
  useEffect
} from 'react';
import { Menu, Row, Col, Dropdown, Typography, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { reset } from 'actions/reset';

import config from 'config';

import Avatar from 'components/Avatar';
import OrganizationLogo from 'components/OrganizationLogo';
import { findTree } from 'utils/tree';
import CreateEditOrganizationModal from 'components/OrganizationProfile/CreateEditOrganizationModal';

import './style.less';

const { Text } = Typography;

const OrgLogos = injectIntl(withRouter(({
  intl,
  location,
  organization,
  mainOrganization,
}) => {
  const t = intl.messages;
  const title = useMemo(
    () => {
      if (location.pathname.includes('organization-profile')) {
        return t.organization;
      } else if (location.pathname.includes('user-profile')) {
        return t.profile;
      }
    },
    [ location, t ]
  );

  const atlasBaseUrl = useMemo(() => {
    return mainOrganization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
  }, [
    mainOrganization,
  ]);

  const adminBaseUrl = useMemo(() => {
    return mainOrganization?.config?.community?.custom_redirect_urls?.admin || config.ADMIN_APP_URL;
  }, [
    mainOrganization,
  ]);

  return (
    <Row type='flex' align='middle'>
      <Col>
        <Link to="/">
          <OrganizationLogo
            className='Nav-logo'
            {...organization}
            showPoweredBy={
              (
                organization?.config || {}
              ).show_powered_by
            }
          />
        </Link>
      </Col>
      <Col>
        <Text className='AppHeader-appTitle'>
          { title }
        </Text>
      </Col>
      {
        (organization?.products.includes('atlas') || 
        organization?.products.includes('community')) && 
          <Col className='AppHeader-productLinks'>
            <Row type='flex' align='middle' gutter={16}>
              { organization?.products.includes('atlas')
                ? <Col>
                    <a
                      href={`${atlasBaseUrl}?toplevelorg=${mainOrganization?.slug}&org=${organization.slug}`}
                      className='AppHeader-linkToApp'
                    >
                      { t.product_atlas }
                    </a>
                  </Col>
                : null
              }
              { organization?.products.includes('community')
                ? <Col>
                    <a
                      href={`${adminBaseUrl}?toplevelorg=${mainOrganization?.slug}&org=${organization.slug}`}
                      className='AppHeader-linkToApp'
                    >
                      { t.product_community }
                    </a>
                  </Col>
                : null
              }
            </Row>
          </Col>
      }
    </Row>
  );
}));

const AvatarDropdown = withRouter(({
  t,
  avatar,
  name,
  memberships = [],
  location
}) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          { memberships.length <= 1 ? null
              :
                <Menu.Item>
                  <Link to="/organizations">
                    { t.avatardropdown_change_organization }
                  </Link>
                </Menu.Item>
          }
         
          {location.pathname.search("/profile") === -1 &&
            (
              <Menu.Item>
                <Link to="/profile">
                  {t.avatardropdown_profile}
                </Link>
              </Menu.Item>
            )
          }
          {location.pathname.search("/organization") === -1 &&
            (
              <Menu.Item>
                <Link to="/organization">
                  {t.avatardropdown_organization}
                </Link>
              </Menu.Item>
            )
          }
          <Menu.Item>
            <Link to="/logout">
              {t.avatardropdown_logout}
            </Link>
          </Menu.Item>
        </Menu>
      }
      className="AppHeader-avatarMenu"
      trigger={['click']}
    >
      <section>
        <Avatar
          shape="circle"
          src={avatar || undefined}
          name={name || undefined}
          icon={<UserOutlined />}
          className='AppHeader-avatar'
        />
        <div
          className='AppHeader-profileName'
        >{name}</div>
        <img
          className='AppHeader-chevron'
          src='/images/assets/chevron-down.svg'
          alt='chevron-down'
        />
      </section>
    </Dropdown>
  );
});

const AppHeader = ({
  intl,
  location,
}) => {
  const t = intl.messages;

  const isConfigPath = useMemo(() => {
    return location.pathname.includes('/organization');
  }, [location.pathname]);

  const {
    data: organization,
    memberships,
    error: createError,
    fetching,
    success: createSuccess
  } = useSelector(state => state.organization);

  const organization_tree = useSelector(state => state.organization_tree);

  const suborganization = useMemo(
    () => {
      if(!organization_tree.tree) {
        return organization;
      }

      return findTree(
        [organization_tree.tree],
        ({ slug }) => slug === organization_tree.current_slug,
      );
    },
    [ organization, organization_tree ]
  );

  const {
    data: profile
  } = useSelector(state => state.profile);

  const {
    avatar,
    name,
  } = profile || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (createError) {
      notification.error({
        message: t.error,
        description:
          t.error_creating_organization
      });
      dispatch(
        reset({subtype: 'ERROR', target:  'organization_creation'})
      );
    }
  }, [createError, dispatch, t.error, t.error_creating_organization]);

  useEffect(() => {
    if (!createError && !fetching && createSuccess) {
      notification.success({
        message: t.success,
        description:
          t.success_creating_organization
      });
      dispatch(
        reset({subtype: 'SUCCESS', target:  'organization_creation'})
      );
    }
  }, [createError, createSuccess, dispatch, fetching, t.success, t.success_creating_organization]);

  const isAplanetSystem = useMemo(() => profile?.email === 'tech@aplanet.org', [profile]);

  return (
    <section className='AppHeader'>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
      >
        <Col
          className="AppHeader__left"
        >
          <OrgLogos
            organization={suborganization}
            mainOrganization={organization}
          />
        </Col>
        <Col
          className="AppHeader__right"
        >
          <Row
            type='flex'
            justify='end'
            align='middle'
            gutter={8}
          >
            { isConfigPath && (isAplanetSystem || organization.is_partner) && 
              suborganization?.permissions?.can_manage_configuration &&
              <Col>
                <CreateEditOrganizationModal
                  is_main_org={isAplanetSystem}
                />
              </Col>
            }
            <Col>
              <AvatarDropdown
                t={t}
                avatar={avatar}
                name={name}
                memberships={memberships}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default injectIntl(
  withRouter(
    AppHeader
  )
);
