import React from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.less';

const Icon = {
  "Alert": "exclamation-triangle",
  "Bin": "trash",
	"Cancel": "times",
	"ESG Cultural": "book-open",
	"ESG Economic": "usd-circle",
	"ESG Environmental": "seedling",
	"ESG Governance": "landmark",
	"ESG Social": "user-friends",
	"Edit": "pen",
	"Save": "save",
}

const AplanetIcon = ({
  name,
  faStyle = "fal",
  size = "20px",
  className,
  style = {},
  iconProps = {}
}) => {
  return (
    <FontAwesomeIcon data-testid={name} className={`AplanetIcon ${className || ""}`} icon={[faStyle, Icon[name] || name]} style={{...style, fontSize: size}} {...iconProps}/>
  );
}

export default injectIntl(AplanetIcon);
