import React from 'react'
import './style.less'
import {Button} from 'antd'

const CustomButton = (props) => (
  <Button
    {...props}
    className={`CustomButton ${props.className ? props.className : ''}`}
  >
    {props.children}
  </Button>
)

export default CustomButton
