import React, { useMemo, useCallback } from 'react';
import {compact} from 'lodash'

import { flattenTree } from 'utils/tree';
import Avatar from 'components/Avatar';

import { Tree } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import './style.less';

const MUCH_ORGANIZATIONS = 10

const treeData = (
  nodes,
  currentSlug,
  hideLogo,
  parent = {},
) => {
  return nodes.map(_node => {
    if(!_node) {
      return null;
    }

    const node = {..._node, parent};

    const {
      name,
      children = [],
    } = node;

    const hideChildrenLogo = hideLogo || node.logo_small === node.parent.logo_small;

    const formattedChildren = compact(treeData(children, currentSlug, hideChildrenLogo, node))

    if(formattedChildren.length === 0 && !node.permissions) return null

    return {
      title: name,
      key: node.id,
      disabled:!node.permissions,
      children: formattedChildren,
      icon: !hideLogo &&
      <Avatar
        shape="square"
        src={node.logo_small || undefined}
        name={node.name || undefined}
        icon={<InfoCircleOutlined />}
        className='OrganizationSelector-avatar'
      />
    }
  }).filter(Boolean);
};


const OrganizationTree = ({
  tree,
  currentOrg,
  changeOrg,
}) => {
  const flat_tree = useMemo(() => flattenTree(tree), [ tree ]);
  const idToSlug = useCallback((id) => flat_tree[id].slug, [ flat_tree ]);
  const treeKeys = !flat_tree.undefined ? 
    Object.values(flat_tree).filter(node => node.permissions).map(node => node.id)
    : []
  
  const getParentKeys = useCallback((parent_id, initAcc) => {
    const parent_parent_id = flat_tree[parent_id].parent_id;
    if(!parent_parent_id) return initAcc;
    else {
      initAcc = [...new Set([...initAcc, parent_parent_id])];
      return getParentKeys(parent_parent_id, initAcc);
    }
  }, [flat_tree]);

  const allKeys = treeKeys.reduce((acc, curr) => {
    const parent_id = flat_tree[curr].parent_id;
    if (!parent_id) return acc;
    return getParentKeys(parent_id, acc);
  }, treeKeys);
  
  const expandedKeys = ((treeKeys.length > MUCH_ORGANIZATIONS) && (treeKeys.length === Object.values(flat_tree).length)) ?  
    [treeKeys[0]] : (treeKeys.length === Object.values(flat_tree).length) ?
    treeKeys : allKeys;

  const handleSelect = useCallback(([id]) => id && id !== currentOrg.id && changeOrg({ id, slug: idToSlug(id) }), [ currentOrg, changeOrg, idToSlug ]);

  if(!tree || tree.length === 0) return null;

  return (
    <div className="OrganizationTree">
      <Tree
        showIcon
        defaultExpandedKeys={expandedKeys}
        onSelect={handleSelect}
        selectedKeys={ currentOrg ? [ currentOrg.id ] : [] }
        treeData={treeData([tree], currentOrg.slug)}
      />
    </div>
  );
};

export default OrganizationTree;
