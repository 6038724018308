import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect, withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { reset } from 'actions/reset'

import CustomInput from 'components/CustomInput'
import CustomButton from 'components/CustomButton'
import CreateEditOrganizationModal from 'components/OrganizationProfile/CreateEditOrganizationModal'
import objectFilter from 'utils/objectFilter'
import _ from 'lodash'

import Loading from 'containers/Loading'
import MainLayout from 'components/MainLayout'
import Information from 'components/OrganizationProfile/Information'
import Configuration from 'components/OrganizationProfile/Configuration'
import Admins from 'components/OrganizationProfile/Admins'
import OrganizationTree from 'components/OrganizationTree'
import Avatar from 'components/Avatar'
import { findTree, flattenTree } from 'utils/tree'
import {
  requestOrganizationTree,
  requestOrganizationDetail,
  requestProfile,
  addMember,
  updateMember,
  updateOrganization,
  changeMember,
  addChildOrg,
  deleteOrg,
  updateConfiguration,
  getConfiguration
} from 'actions/api';
import {
  switchCurrentOrganization,
  setOrganization,
} from 'actions/organization'

import {
  Affix,
  Layout,
  Row,
  Col,
  Card,
  Tabs,
  Modal,
  Input,
  notification,
  Tooltip
} from 'antd'
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import './style.less'
import { parseReports } from '../../components/OrganizationProfile/SettingsTemplate';

const { Sider } = Layout
const { TabPane } = Tabs

const EDITABLE_PRODUCT_SUBSCRIPTION_DEFAULT = {
  community: ['preferred_languages'],
  atlas: ['features', 'enabled_reports', 'preferred_languages', 'cycle_date_options'],
};

const OrganizationProfile = ({ intl, location }) => {
  const t = intl.messages
  const { slug } = useParams()

  const [editingName, setEditingName] = useState()
  const [name, setName] = useState()
  const [activeTab, setActiveTab] = useState()
  const [newOrgName, setNewOrgName] = useState()
  const [showNewOrgModal, setShowNewOrgModal] = useState()

  // Store and actions
  const dispatch = useDispatch()
  const {
    data: organization,
    memberships,
    changing: changingOrganization,
  } = useSelector((state) => state.organization)

  const { data: profile } = useSelector((state) => state.profile)

  const organization_tree = useSelector((state) => state.organization_tree)

  const isSystemAdmin = useMemo(() => profile && profile?.role === 'system', [profile]);

  const {
    data: settings,
    fetching: fetchingConf,
    pushing: pushingConf,
  } = useSelector((state) => state.organization_configuration)
  const loadingSettings = useMemo(() => fetchingConf || pushingConf, [fetchingConf, pushingConf])

  useEffect(() => {
    if (organization) {
      dispatch(requestProfile(organization.slug))
      dispatch(requestOrganizationTree(organization.slug))
    }
  }, [organization, dispatch])

  const suborganization = useMemo(() => {
    if (!organization_tree.tree) {
      return organization
    }

    const subOrg = findTree(
      [organization_tree.tree],
      ({ slug }) => slug === organization_tree.current_slug
    )
    dispatch(getConfiguration(organization.slug, subOrg.slug))
    return subOrg
  },
    //eslint-disable-next-line
    [organization, organization_tree])

  useEffect(() => {
    if (suborganization && suborganization.name) {
      setName(suborganization.name)
    }
  }, [suborganization])

  const {
    error: editError,
    fetching,
    success: editSuccess,
    pushing,
    data: organizationDetail,
  } = useSelector((state) => state.organization_detail)

  useEffect(() => {
    if (editError) {
      notification.error({
        message: t.error,
        description: t.error_editting_organization,
      })
      dispatch(reset({ subtype: 'ERROR', target: 'organization_edit' }))
    }
  }, [editError, dispatch, t.error, t.error_editting_organization])

  useEffect(() => {
    if (!editError && !fetching && editSuccess) {
      notification.success({
        message: t.success,
        description: t.success_editting_organization,
      })
      dispatch(reset({ subtype: 'SUCCESS', target: 'organization_edit' }))
    }
  }, [
    editError,
    editSuccess,
    dispatch,
    fetching,
    t.success,
    t.success_editting_organization,
  ])

  useEffect(() => {
    if (
      !fetching &&
      organization &&
      suborganization &&
      organization.slug &&
      suborganization.slug &&
      (!organizationDetail || organizationDetail.slug !== suborganization.slug)
    ) {
      dispatch(
        requestOrganizationDetail(organization.slug, suborganization.slug)
      )
    }
  }, [organization, suborganization, organizationDetail, fetching, dispatch])

  useEffect(() => {
    if (!organization && slug && !changingOrganization) {
      dispatch(setOrganization(slug))
    }
  }, [dispatch, organization, slug, changingOrganization])

  const onChangeOrganizaion = useCallback(
    (organization) => dispatch(switchCurrentOrganization(organization)),
    [dispatch]
  )

  const handleAddAdmin = useCallback(
    ({
      name,
      email,
      details,
      atlas_permission,
      community_permission,
      general_permission,
    }) => {
      dispatch(
        addMember(
          organization.slug,
          suborganization.slug,
          name,
          email,
          details,
          atlas_permission,
          community_permission,
          general_permission,
        )
      )
    },
    [dispatch, organization, suborganization]
  )

  const handleUpdateAdmin = useCallback(
    (
      member_slug,
      {
        status,
        details,
        atlas_permission,
        community_permission,
        general_permission,
      }
    ) => {
      dispatch(
        updateMember(
          organization.slug,
          suborganization.slug,
          member_slug,
          status,
          details,
          atlas_permission,
          community_permission,
          general_permission
        )
      )
    },
    [dispatch, organization, suborganization]
  )

  const handleChangeAdmin = useCallback(
    (member_slug, action, options) => {
      dispatch(
        changeMember(
          organization.slug,
          suborganization.slug,
          member_slug,
          action,
          options
        )
      )
    },
    [dispatch, organization, suborganization]
  )

  const organizationNamesById = useMemo(() => {
    const organizations = {}

    if (organization_tree.tree) {
      const flatTree = flattenTree(organization_tree.tree)
      Object.keys(flatTree).forEach((organizationId) => {
        organizations[organizationId] = flatTree[organizationId].name
      })
    }
    return organizations
  }, [organization_tree])

  const handleUpdateOrganization = useCallback(
    (values) => {
      // Update just name/logos
      dispatch(
        updateOrganization(organization.slug, suborganization.slug, values)
      )
    },
    [organization, suborganization, dispatch]
  )

  const handleUpdateConfiguration = useCallback((settings) => {
    dispatch(updateConfiguration(organization.slug, suborganization.slug, settings))
  }, [organization, suborganization, dispatch])

  const handleSubmitName = useCallback(() => {
    handleUpdateOrganization({ name })
    setEditingName(false)
  }, [handleUpdateOrganization, name])

  const toggleNameInput = useCallback(() => setEditingName(!editingName), [
    editingName,
  ])

  const onAddChildOrg = useCallback(() => {
    dispatch(
      addChildOrg(organization.slug, suborganization.slug, { name: newOrgName })
    )
    setShowNewOrgModal(false)
    setNewOrgName('')
  }, [dispatch, newOrgName, organization, suborganization])

  const editOrgValues = useMemo(() => {
    let values = {...organizationDetail, ...organizationDetail?.profile};
    values = {...values, product_config: Object.keys(organizationDetail?.settings || []).reduce((acc, curr) => {
      if (!!Object.keys(organizationDetail.settings[curr]).length && (curr === 'atlas' || curr === 'community')) {
        acc[curr] = objectFilter((EDITABLE_PRODUCT_SUBSCRIPTION_DEFAULT)[curr] || [])(organizationDetail.settings[curr]);
      }
      return acc;
    }, {})
    };
    return organizationDetail ? values : {};
  }, [organizationDetail]);

  const onDeleteOrg = useCallback(() => {
    Modal.confirm({
      title: t.organization_profile_delete_org,
      content: t.organization_profile_delete_content,
      okText: t.organization_profile_delete_ok,
      okType: 'danger',
      cancelText: t.organization_profile_delete_cancel,
      onOk() {
        dispatch(deleteOrg(organization.slug, suborganization.slug))
      },
    })
  }, [t, dispatch, organization, suborganization])

  const disabledDeleteOrgButton = useMemo(() => suborganization?.children?.length || !suborganization.parent_id, [suborganization]);
  const disabledDeleteOrgId = useMemo(() => 
    !suborganization.parent_id ? 'organization_profile_disabled_parent_delete_button'
    : suborganization?.children?.length ? 'organization_profile_disabled_haschildren_delete_button'
    : null,
    [suborganization]
  );

  if (slug && !memberships.map((m) => m.slug).includes(slug)) {
    return <Redirect to="/404" />
  }

  if (
    memberships.length > 1 &&
    !organization &&
    (!slug || changingOrganization)
  ) {
    return (
      <Redirect
        to={{
          pathname: '/organizations',
          state: { redirectUrl: `/organization${location.search}` },
        }}
      />
    )
  }

  return (
    <div className="OrganizationProfile">
      <MainLayout
        errorMessage={intl.formatMessage({id: 'error_boundary_organization_profile_message'})}
      >
        <Sider width="25%">
          <Affix offsetTop={120}>
            <Row className="OrganizationProfile-tree">
              <Col span={24}>
                <OrganizationTree
                  tree={organization_tree.tree || []}
                  changeOrg={onChangeOrganizaion}
                  currentOrg={suborganization}
                />
              </Col>
            </Row>
          </Affix>
        </Sider>
        <Layout>
          <Row>
            <Col span={24}>
              <Card className="OrganizationProfile-card">
                {suborganization ? (
                  <>
                    <Row type="flex" justify="space-between">
                      <Col></Col>
                      <Col>
                        {suborganization.products.includes('community') ? (
                          <span className="OrganizationProfile-label">
                            {t.organization_profile_community_rating}
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                      <Col>
                        <Row type="flex" align="middle" gutter={10}>
                          <Col>
                            <Avatar
                              shape="square"
                              size={60}
                              src={suborganization.logo_small || undefined}
                              name={suborganization.name || undefined}
                              icon={<InfoCircleOutlined />}
                              className="OrganizationProfile-avatar"
                            />
                          </Col>
                          <Col>
                            <Row type="flex" align="middle" gutter={10}>
                              {editingName ? (
                                <>
                                  <Col>
                                    <CustomInput
                                      name="name"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </Col>
                                  <Col>
                                    <Row type="flex" align="middle" gutter={5}>
                                      {name ? (
                                        <Col>
                                          <CheckCircleOutlined
                                            onClick={handleSubmitName}
                                          />
                                        </Col>
                                      ) : null}
                                      <Col>
                                        <CloseCircleOutlined
                                          onClick={toggleNameInput}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col>
                                    <span className="OrganizationProfile-name">
                                      {suborganization.name}
                                    </span>
                                  </Col>
                                  {suborganization?.permissions
                                    ?.can_manage_configuration && (
                                    <Col>
                                      <EditOutlined onClick={toggleNameInput} />
                                    </Col>
                                  )}
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={5}>
                          {suborganization?.permissions
                            ?.can_manage_configuration && (
                            <>
                              {suborganization?.products.includes('atlas') && (
                                <Col>
                                  <CustomButton
                                    icon={<PlusOutlined />}
                                    onClick={() => setShowNewOrgModal(true)}
                                  >
                                    {t.organization_profile_add_child_org}
                                  </CustomButton>
                                </Col>
                              )}
                              { !_.isEmpty(editOrgValues) &&
                                  <Col>
                                    <CreateEditOrganizationModal
                                      values={editOrgValues}
                                      editting
                                      is_main_org={organizationDetail.is_my_main_org}
                                    />
                                  </Col>
                              }
                              {
                              <Tooltip title={disabledDeleteOrgButton ? intl.formatMessage({ id: disabledDeleteOrgId }) : null}>
                                <Col style={{ cursor: disabledDeleteOrgButton ? 'not-allowed' : 'pointer' }}>
                                    <CustomButton
                                      className="OrganizationProfile-delete-org"
                                      disabled={disabledDeleteOrgButton}
                                      danger
                                      icon={<DeleteOutlined />}
                                      onClick={() => onDeleteOrg()}
                                    >
                                      {t.organization_profile_delete_org}
                                    </CustomButton>
                                </Col>
                              </Tooltip>
                              }
                            </>
                          )}
                          <Col>
                            {suborganization.products.includes('community') ? (
                              <figure className="OrganizationProfile-score">
                                <span>
                                  {Number(suborganization.score).toPrecision(2)}
                                </span>
                                <img src="/images/star.svg" alt="" />
                              </figure>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {fetching || !organizationDetail ? (
                          <Row type="flex" justify="center" align="middle">
                            <Loading.Block />
                          </Row>
                        ) : (
                          <Tabs
                            activeKey={activeTab}
                            onChange={setActiveTab}
                            defaultActiveKey="information"
                            className="OrganizationProfile-tabs"
                          >
                            <TabPane
                              tab={t.organization_profile_information}
                              key="information"
                            >
                              <Information
                                isSystemAdmin={isSystemAdmin}
                                organization={organizationDetail}
                                suborganization={suborganization}
                                handleUpdateOrganization={
                                  handleUpdateOrganization
                                }
                              />
                            </TabPane>
                            {suborganization.permissions &&
                            suborganization.permissions
                              .can_manage_permissions ? (
                              <TabPane
                                tab={t.organization_profile_admins}
                                key="admins"
                              >
                                <Admins
                                  organizationId={suborganization.id}
                                  organization={organizationDetail}
                                  mainOrganizationSlug={organization.slug}
                                  addAdmin={handleAddAdmin}
                                  updateAdmin={handleUpdateAdmin}
                                  changeAdmin={handleChangeAdmin}
                                  pushing={pushing}
                                  allowedProducts={suborganization.products}
                                  organizationNamesById={organizationNamesById}
                                  isSystemAdmin={isSystemAdmin}
                                  profileId = {profile?.id}
                                />
                              </TabPane>
                            ) : null}
                            {suborganization?.permissions
                              ?.can_manage_configuration && (
                                <TabPane tab={t.organization_profile_configuration} key="configuration">
                                <Configuration
                                  initialSettings={settings}
                                  availableDimensions={parseReports(settings?.atlas?.enabled_reports)}
                                  isLoading={loadingSettings}
                                  isSystemAdmin={isSystemAdmin}
                                  handleUpdateConfiguration={
                                    handleUpdateConfiguration
                                  }
                                />
                              </TabPane>
                            )}
                          </Tabs>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Layout>
      </MainLayout>

      <Modal
        title={t.organization_profile_add_child_org}
        visible={showNewOrgModal}
        onOk={onAddChildOrg}
        okText={t.organization_profile_add_org_create}
        cancelText={t.organization_profile_add_org_cancel}
        okButtonProps={{
          disabled: !newOrgName,
        }}
        onCancel={() => setShowNewOrgModal(false)}
      >
        <Input
          placeholder={t.organization_profile_organization_name}
          value={newOrgName}
          onChange={(e) => setNewOrgName(e.target.value)}
        />
      </Modal>
    </div>
  )
}

export default injectIntl(withRouter(OrganizationProfile))
