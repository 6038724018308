import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LocaleProvider } from 'antd';
import qs from 'query-string';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import Loading from 'containers/Loading';
import Routes from 'containers/Routes';

import { saveState, loadState } from 'store/persistence';
import { hydrateStore } from 'actions/store';
import {
  requestIdentity,
  requestOrganizationProfile,
} from 'actions/auth';
import updateTheme from 'utils/updateTheme';

const Root = ({
  location,
  intl
}) => {
  const [hydrated, setHydrated] = useState(false);
  const [refreshStarted, setRefreshStarted] = useState(false);
  const [publicProfileRequested, setPublicProfileRequested] = useState(false);
  const [publicProfileLoaded, setPublicProfileLoaded] = useState(false);

  const dispatch = useDispatch();
  const {
    organization,
  } = useSelector(state => state);

  const {
    refreshing_token,
    logged_in,
  } = useSelector(state => state.auth);

  // Save state upon auth/org change
  useEffect(() => {
    if(hydrated) {
      saveState({
        organization_slug: (organization.data || {}).slug,
      });
    }
  }, [
    hydrated,
    organization.data,
  ]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(state => dispatch(hydrateStore(state)))
      .then(() => setHydrated(true));
  }, [ dispatch ]);

  useEffect(() => {
    if(!organization.data || organization.data.is_default_org) {
      if(!publicProfileRequested && location.search) {
        const parsed = qs.parse(location.search);
        if(parsed.org) {
          setPublicProfileRequested(true);
          dispatch(
            requestOrganizationProfile(parsed.org)
          );
        }
      }
      return
    }
    const theme = organization.data?.config?.general?.theme;
    updateTheme(theme);
    if(publicProfileRequested) {
      // NOTICE: Half a second to load the theme
      setTimeout(() => setPublicProfileLoaded(true), 500);
    }
  }, [
    dispatch,
    publicProfileRequested,
    organization.data,
    location.search,
  ]);

  // Refresh access token when loading the app
  useEffect(() => {
    if(hydrated) {
      dispatch( requestIdentity() );
      setRefreshStarted(true);
    }
  }, [
    hydrated,
    dispatch,
  ]);

  const isReady = hydrated && refreshStarted &&
    (
      logged_in ||
      !refreshing_token
    ) &&
    (
      !publicProfileRequested ||
      (!organization.fetching && publicProfileLoaded)
    );

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
        buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
        descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
        customErrorImage="/images/error_image.png"
      />
    }>
      <LocaleProvider locale={intl.formats.antd}>
        { isReady ? <Routes /> : <Loading.Big /> }
      </LocaleProvider>
    </Sentry.ErrorBoundary>
  );
}

export default withRouter(injectIntl(Root));


