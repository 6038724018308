import { combineReducers } from 'redux-loop';
import { intlReducer } from 'react-intl-redux';
import { auth } from 'reducers/auth';
import { password_policy } from 'reducers/password_policy';
import { password_recovery } from 'reducers/password_recovery';
import { signin } from 'reducers/signin';
import { organization } from 'reducers/organization';
import { api_requests } from 'reducers/api_requests';
import { organization_tree } from 'reducers/organization_tree';
import { organization_detail } from 'reducers/organization_detail';
import { organization_configuration } from 'reducers/organization_configuration';
import { profile } from 'reducers/profile';
import { permissions } from 'reducers/permissions';
import { error_notification } from 'reducers/error_notification';
import { industry } from 'reducers/industry';
import { email } from 'reducers/email';

const reducers = (history) => combineReducers({
  intl: intlReducer,
  auth,
  password_policy,
  password_recovery,
  signin,
  organization,
  api_requests,
  organization_tree,
  organization_detail,
  organization_configuration,
  profile,
  permissions,
  error_notification,
  industry,
  email,
});

export default reducers;
