import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

const TARGET = 'organization_creation';
export const CYCLE_DATE_OPTIONS = ['01-01'];
export const COMMUNITY_LANGUAGES = ['en-US', 'es-ES', 'pt-PT', 'pt-BR', 'eu-ES'];
export const ATLAS_LANGUAGES = ['en-US', 'es-ES', 'pt-PT', 'pt-BR', 'fr-FR', 'it-IT', 'ca-ES'];
export const DEFAULT_LANGUAGES = Array.from( new Set ([ ...COMMUNITY_LANGUAGES, ...ATLAS_LANGUAGES ]) );
const INVISIBLE_PRODUCTS = [
  'sso',
];

const requestProfile = async (slug) => {
  const response = await fetch(`${config.API_URL}/organization_profile/${slug}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json'
    },
  })

  const result = await parseResponse(response);
  return result;
};

const profileSuccess = (organization) => ({
  type: 'ORGANIZATION_PROFILE_COMPLETE',
  organization,
});

const profileFail = ({ code, text }) => ({
  type: 'ORGANIZATION_PROFILE_FAILED',
  code,
  text,
});

const VALID_STATUSES = [
  'new',
  'onboarding',
  'active',
  'deleted',
  'churned',
  'undefined',
  // TODO: remove these two after the statuses are fixed
  'invited',
  'pending_email',
];


const DEFAULT_ORGANIZATION = {
  logo: `/images/aplanet.png`,
  logo_small: `/images/aplanet_a.png`,
  name: 'Aplanet',
  slug: 'aplanet',
  id: 1,
  region: 'ES',
  is_default_org: true,
  is_mini_profile: true,
  languages: DEFAULT_LANGUAGES,
  role: 'user',
  status: 'active',
  products: [], // TODO: Default products
};

const initialState = {
  fetching: false,
  slug: null, // NOTICE: We do not prefer if you mark the default org
  data: DEFAULT_ORGANIZATION,
  memberships: [
    DEFAULT_ORGANIZATION,
  ],
  error: null,
  changing: false,
  success: false
};

const getOrganizationFromResponse = (response) => {
  const {
    organization_is_partner,
    organization_name,
    organization_slug,
    organization_logo,
    organization_logo_small,
    name: provided_name,
    slug: provided_slug,
    logo: provided_logo,
    logo_small: provided_logo_small,
    region,
    product_config,
    products: base_products,
    role = 'admin',
    status,
    organization_id: id,
  } = response;

  // NOTICE: if we are processing a member we prefer organization_*
  const name = organization_name || provided_name;
  const slug = organization_slug || provided_slug;
  const logo = organization_logo || provided_logo;
  const logo_small = organization_logo_small || provided_logo_small;
  const is_partner = organization_is_partner;

  //if(slug === initialState.slug) {
  //  return initialState.data;
  //}

  const products = (base_products || [])
    .filter(
      product => !INVISIBLE_PRODUCTS.includes(product)
    )

  return {
    is_partner,
    name,
    slug,
    logo,
    logo_small,
    region,
    config: product_config,
    languages: DEFAULT_LANGUAGES,
    products,
    role,
    status,
    id,
  };
};

const reducer = (state = initialState, action) => {
  let organizations,
      org,
      memberships;

  switch (action.type) {

    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: true,
        error: null,
        success: false
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      memberships = state.data.is_partner ? [...state.memberships, getOrganizationFromResponse(response.result)] : state.memberships;
      return {
        ...state,
        memberships,
        fetching: false,
        error: null,
        success: true
      };
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code, text } = action;
      return {
        ...state,
        fetching: false,
        error: {
          code,
          text
        },
        success: false
      };

    case 'RESET_SUCCESS':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        success: false
      };

    case 'RESET_ERROR':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        error: null
      };

    case 'IDENTITY_COMPLETE':
    case 'SUBMIT_RECOVERED_PASSWORD_COMPLETE':
    case 'LOGIN_COMPLETE':
      if(!action.user) {
        return state;
      }
      organizations = action.user.organizations;
      // eslint-disable-line no-fallthrough
    case 'LOOKUP_COMPLETE':
      organizations = organizations || action.organizations || [];
      if(
        !organizations ||
        organizations.length === 0
      ) {
        return state;
      }
      if(organizations.length > 1) {
        memberships = organizations.filter(org => VALID_STATUSES.includes(org.status)).map(getOrganizationFromResponse);
        org = memberships.find(
          ({
            slug,
            products = [],
          }) => {
            return state.slug &&
              slug === state.slug
          }
        );
        return {
          ...state,
          data: org || null,
          slug: (org || {}).slug || null,
          memberships,
        };
      }

      org = getOrganizationFromResponse(organizations[0]);
      return {
        ...state,
        data: org,
        slug: org.slug,
        memberships: [ org ],
      };
    case 'ORGANIZATION_RESET':
      if(state.memberships.length > 1) {
        return {
          ...state,
          data: null,
          slug: null,
          changing: true,
        };
      }
      return state;
    case 'ORGANIZATION_SET':
      org = state.memberships.find(({ slug }) => slug === action.slug);

      if(org) {
        return {
          ...state,
          data: org,
          slug: org.slug,
          changing: false,
        };
      }
      return state;
    case 'ORGANIZATION_PROFILE_REQUEST':
      return loop({
        ...state,
        fetching: true,
        slug: action.slug,
      }, Cmd.run(requestProfile, {
        successActionCreator: profileSuccess,
        failActionCreator: profileFail,
        args: [action.slug],
      })
    );
    case 'ORGANIZATION_PROFILE_COMPLETE':
      org = getOrganizationFromResponse(action.organization);
      return {
        ...state,
        fetching: false,
        slug: (action.organization || {}).slug || null,
        data: {
          ...org,
          is_mini_profile: true,
        },
        memberships: state.memberships,
      };
    case 'ORGANIZATION_PROFILE_FAILED':
      return {
        ...state,
        fetching: false,
        slug: null,
      };
    case 'ORGANIZATION_UPDATE':
      if (action.organization.id === state.data.id) {
        return {
          ...state,
          data: {
            ...state.data,
            ...action.organization,
            config: action.organization.product_config,
          },
        };
      }
      return state;
    case 'IDENTITY_FAILED':
    case 'LOOKUP_FAILED':
    case 'REFRESH_TOKEN_FAILED':
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
    case 'LOGOUT_DONE':
    case 'LOGOUT_FAILED':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state.organization_slug ? {
        ...state,
        slug: action.state.organization_slug,
      } : state;
    default:
      return state;
  }
};

export {
  reducer as organization,
  DEFAULT_ORGANIZATION as defaultOrganization,
};
