import config from 'config.js';
import qs from 'query-string';

const DEFAULT_ROUTE = '/organization';
const PRODUCTS = [
  'atlas',
  'community',
];

const APPS = [
  'atlas',
  'mobile',
  'admin',
];

const PRODUCTS_WITH_USER_ROLE = [
  'community',
];

const checkRole = (product, role) => {
  if(role === 'user' && !PRODUCTS_WITH_USER_ROLE.includes(product)) {
    return null
  }
  return role;
};

// Targets the auth API because of organization-based redirections
export const getRedirectUrl = (product, _role = 'user', _orgSlug = '') => {
  let role = checkRole(product, _role);
  return PRODUCTS.includes(product) && role
    ? `${config.AUTH_URL}/redirect/${product}/${role}/${_orgSlug}`
    : null;
};

export const getDefaultRoute = (search, org) => {
  const newSearch = {...qs.parse(search), r: true}
  return `${DEFAULT_ROUTE}${org ? `/${org}` : ""}?${qs.stringify(newSearch)}`
}

export const productAndRoleToApp = (product, role) => {
  switch(product) {
    case 'community':
      return role === 'user' ? 'mobile' : 'admin';
    case 'atlas':
      return 'atlas';
    default:
      return null;
  }
};

export const appToProduct = (app) => {
  switch(app) {
    case 'mobile':
      return 'community';
    case 'admin':
      return 'community';
    case 'atlas':
      return 'atlas';
    default:
      return;
  }
};

export const appToProductAndRole = (app) => {
  switch(app) {
    case 'mobile':
      return ['community', 'user'];
    case 'admin':
      return ['community', 'admin'];
    case 'atlas':
      return ['atlas', 'admin'];
    default:
      return [];
  }
};

export const appAdminURLs =
  { ...APPS.reduce((acc, p) => ({...acc, [p]: getRedirectUrl.bind(null, appToProductAndRole(p)[0], appToProductAndRole(p)[1])}), {}) }

export const getAppFromQueryString = (search) => {
  const parsed = qs.parse(search);

  if(parsed.product && parsed.role) {
    return productAndRoleToApp(
      parsed.product,
      parsed.role
    );
  }

  if(parsed.app) {
    return parsed.app;
  }

  return null;
};

export const getEmailFromQueryString = (search) => {
  const parsed = qs.parse(search);

  return parsed.email ? decodeURIComponent(parsed.email) : null;
}
