import intl from 'intl';
import { updateIntl } from 'react-intl-redux';
import { createIntl, createIntlCache } from 'react-intl';

// TODO: see https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md
// Locale Data
//import locale_en from '@formatjs/intl-relativetimeformat/dist/locale-data/en';
//import locale_es from '@formatjs/intl-relativetimeformat/dist/locale-data/es';
//import locale_pt from '@formatjs/intl-relativetimeformat/dist/locale-data/pt';
//import locale_fr from '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
//import locale_ca from '@formatjs/intl-relativetimeformat/dist/locale-data/ca';
//import locale_it from '@formatjs/intl-relativetimeformat/dist/locale-data/it';

// Date-fns locale
import dateFns_en from 'date-fns/locale/en-US';
import dateFns_es from 'date-fns/locale/es';
import dateFns_eu from 'date-fns/locale/es'; // There's no eu
import dateFns_pt from 'date-fns/locale/pt';
import dateFns_fr from 'date-fns/locale/fr';
import dateFns_ca from 'date-fns/locale/ca';
import dateFns_it from 'date-fns/locale/it';

// This is a polyfill
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/eu.js';
import 'intl/locale-data/jsonp/pt.js';
import 'intl/locale-data/jsonp/fr.js';
import 'intl/locale-data/jsonp/ca.js';
import 'intl/locale-data/jsonp/it.js';

import messages_es_ES from './es-ES.json';
import messages_eu_ES from './eu-ES.json';
import messages_en_US from './en-US.json';
import messages_pt_BR from './pt-BR.json';
import messages_pt_PT from './pt-PT.json';
import messages_fr_FR from './fr-FR.json';
import messages_ca_ES from './ca-ES.json';
import messages_it_IT from './it-IT.json';

import messages_quote_es_ES from './quote/es-ES.json';
import messages_quote_eu_ES from './quote/eu-ES.json';
import messages_quote_en_US from './quote/en-US.json';
import messages_quote_pt_BR from './quote/pt-BR.json';
import messages_quote_pt_PT from './quote/pt-PT.json';
import messages_quote_fr_FR from './quote/fr-FR.json';
import messages_quote_ca_ES from './quote/ca-ES.json';
import messages_quote_it_IT from './quote/it-IT.json';

import messages_industry_es_ES from './industry/es-ES.json';
import messages_industry_eu_ES from './industry/eu-ES.json';
import messages_industry_en_US from './industry/en-US.json';
import messages_industry_pt_BR from './industry/pt-BR.json';
import messages_industry_pt_PT from './industry/pt-PT.json';
import messages_industry_fr_FR from './industry/fr-FR.json';
import messages_industry_ca_ES from './industry/ca-ES.json';
import messages_industry_it_IT from './industry/it-IT.json';

import messages_sector_es_ES from './sector/es-ES.json';
import messages_sector_eu_ES from './sector/eu-ES.json';
import messages_sector_en_US from './sector/en-US.json';
import messages_sector_pt_BR from './sector/pt-BR.json';
import messages_sector_pt_PT from './sector/pt-PT.json';
import messages_sector_fr_FR from './sector/fr-FR.json';
import messages_sector_ca_ES from './sector/ca-ES.json';
import messages_sector_it_IT from './sector/it-IT.json';

// Ant Design
import antd_es from 'antd/lib/locale-provider/es_ES';
import antd_eues from 'antd/lib/locale-provider/es_ES';
import antd_en from 'antd/lib/locale-provider/en_US';
import antd_ptbr from 'antd/lib/locale-provider/pt_BR';
import antd_ptpt from 'antd/lib/locale-provider/pt_PT';
import antd_fr from 'antd/lib/locale-provider/fr_FR';
import antd_ca from 'antd/lib/locale-provider/ca_ES';
import antd_it from 'antd/lib/locale-provider/it_IT';

const en_US = {
  locale: 'en-US',
  messages: {
    ...messages_en_US,
    quote: messages_quote_en_US,
    industry: messages_industry_en_US,
    sector: messages_sector_en_US,
  },
  formats: {
    antd: antd_en,
    dateFns: dateFns_en,
  },
};

const es_ES = {
  locale: 'es-ES',
  messages: {
    ...messages_es_ES,
    quote: messages_quote_es_ES,
    industry: messages_industry_es_ES,
    sector: messages_sector_es_ES,
  },
  formats: {
    antd: antd_es,
    dateFns: dateFns_es,
  },
};

const eu_ES = {
  locale: 'eu-ES',
  messages: {
    ...messages_eu_ES,
    quote: messages_quote_eu_ES,
    industry: messages_industry_eu_ES,
    sector: messages_sector_eu_ES,
  },
  formats: {
    antd: antd_eues,
    dateFns: dateFns_eu,
  },
};

const pt_BR = {
  locale: 'pt-BR',
  messages: {
    ...messages_pt_BR,
    quote: messages_quote_pt_BR,
    industry: messages_industry_pt_BR,
    sector: messages_sector_pt_BR,
  },
  formats: {
    antd: antd_ptbr,
    dateFns: dateFns_pt,
  },
};

const pt_PT = {
  locale: 'pt-PT',
  messages: {
    ...messages_pt_PT,
    quote: messages_quote_pt_PT,
    industry: messages_industry_pt_PT,
    sector: messages_sector_pt_PT,
  },
  formats: {
    antd: antd_ptpt,
    dateFns: dateFns_pt,
  },
};

const fr_FR = {
  locale: 'fr-FR',
  messages: {
    ...messages_fr_FR,
    quote: messages_quote_fr_FR,
    industry: messages_industry_fr_FR,
    sector: messages_sector_fr_FR,
  },
  formats: {
    antd: antd_fr,
    dateFns: dateFns_fr,
  }
};

const it_IT = {
  locale: 'it-IT',
  messages: {
    ...messages_it_IT,
    quote: messages_quote_it_IT,
    industry: messages_industry_it_IT,
    sector: messages_sector_it_IT,
  },
  formats: {
    antd: antd_it,
    dateFns: dateFns_it,
  }
};

const ca_ES = {
  locale: 'ca-ES',
  messages: {
    ...messages_ca_ES,
    quote: messages_quote_ca_ES,
    industry: messages_industry_ca_ES,
    sector: messages_sector_ca_ES,
  },
  formats: {
    antd: antd_ca,
    dateFns: dateFns_ca,
  }
};

const config = {
  'en': {
    default: en_US,
    'en-US': en_US,
  },
  'es': {
    default: es_ES,
    'es-ES': es_ES,
  },
  'pt': {
    default: pt_BR,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT,
  },
  fr: {
    default: fr_FR,
    'fr-FR': fr_FR
  },
  it: {
    default: it_IT,
    'it-IT': it_IT
  },
  ca: {
    default: ca_ES,
    'es-ES': ca_ES
  },
  eu: {
    default: eu_ES,
    'eu-ES': eu_ES
  },
  default: {
    default: en_US,
  },
};

const defaultLocale = 'en-US';

const getConfig = (locale = defaultLocale) => {
  const langConfig = config[locale.slice(0, 2)] || config.default;
  return langConfig[locale] || langConfig.default;
};

const setupIntl = () => {
  if (typeof window.Intl === 'undefined') window.Intl = intl;
  const config = getConfig(navigator?.language || navigator?.userLanguage || 'en-US');

  return config;
};

const changeLocale = (locale) => {
  const config = getConfig(locale);
  return updateIntl(config);
};

const cache = createIntlCache()
let intlObj = createIntl(getConfig(navigator?.language || navigator?.userLanguage || defaultLocale), cache)

const formatMessage = (key, values = {}) => intlObj.formatMessage({id: key}, values)

export {
  setupIntl,
  changeLocale,
  formatMessage
};


