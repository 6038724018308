const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_SAMPLE_RATE: +process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'https://localhost:7501',
  API_URL: process.env.REACT_APP_API_URL || 'https://localhost:7500/accounts',
  //VOLUNTEER_APP_URL: process.env.REACT_APP_VOLUNTEER_APP_URL || 'https://localhost:3000',
  ADMIN_APP_URL: process.env.REACT_APP_ADMIN_APP_URL || 'https://localhost:3001',
  ATLAS_APP_URL: process.env.REACT_APP_ATLAS_APP_URL || 'https://localhost:3003',
  ORGANIZATION_COOKIE_DOMAIN: process.env.REACT_APP_ORGANIZATION_COOKIE_DOMAIN || 'localhost',
  ORGANIZATION_COOKIE_NAME: process.env.REACT_APP_ORGANIZATION_COOKIE_NAME || 'aplanet_organization_development',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',

}

export default config;
