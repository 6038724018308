import React, {
  useMemo,
  useCallback,
} from 'react'

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { injectIntl } from 'react-intl';

import { changeLocale } from '../../intl'; // NOTICE: 'intl' is a node package
import './style.less'

import Select from 'components/CustomSelect';

const LanguageSelector = ({
  intl,
}) => {
  const languages = useMemo(() => [
      'en-US',
      'es-ES',
      'pt-BR',
      'pt-PT',
      'fr-FR',
      'it-IT',
      'ca-ES',
      'eu-ES',
    ].map(value => ({ value, label: intl.formatMessage({id:`language_${value}`}) })), [ intl ]);

  // Store and actions
  const dispatch = useDispatch();

  const {
    locale,
  } = useSelector(state => state.intl);

  const updateLocale = useCallback(locale => {
    dispatch(
      changeLocale(locale)
    );
  }, [ dispatch ]);

  return (
    <Select
      className="LanguageSelector"
      value={locale}
      onChange={updateLocale}
    >
      {
        languages.map(({
          value,
          label,
        }) => (
          <Select.Option
            key={value}
            value={value}
          >
            { label }
          </Select.Option>
        ))
      }
    </Select>
  );
}

export default injectIntl(
  LanguageSelector
);

