import React, {
	useEffect,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { CustomToggle, CustomAddressSearch, CustomInput, CustomMultiSelect } from 'aplanet-ui-kit';
import {
	Col,
	Row,
	Form,
	Checkbox
} from 'antd';

import NewCustomSelect  from 'components/NewCustomSelect';

import { requestIndustries } from 'actions/api';
import Logo from 'components/Logo';
import CycleDateInput from 'components/CycleDateInput';
import _ from 'lodash';
import {
	COMMUNITY_LANGUAGES,
	ATLAS_LANGUAGES,
	CYCLE_DATE_OPTIONS,
} from 'reducers/organization';

import {
	useSelector,
	useDispatch,
} from 'react-redux';
import { injectIntl } from 'react-intl';

const PRODUCT_SLUGS = {
	community: 'community',
	atlas: 'atlas',
};

const CreateEditOrganizationForm = ({
	intl,
	editting,
	suborganization,
	organization,
	is_aplanet_admin,
	logos,
	setLogos,
	values,
	handleChange,
	handleChangeEvent,
	errors,
	is_main_org,
	used_cycle_dates = [],
	used_languages = [],
}) => {
	const t = intl.messages;
	const ATLAS_ENABLED_REPORTS = [
		{ label: intl.formatMessage({ id: 'report_sdg' }), value: 'sdgc' },
		{ label: intl.formatMessage({ id: 'report_gri' }), value: 'gri' },
		{ label: intl.formatMessage({ id: 'report_gri-2021' }), value: 'gri-2021' },
		{ label: intl.formatMessage({ id: 'reports_bcorp' }), value: 'bcorp' },
		{ label: intl.formatMessage({ id: 'feature_atlas_equality' }), value: 'equality' },
		{ label: intl.formatMessage({ id: 'reports_einf' }), value: 'einf' },
		{ label: intl.formatMessage({ id: 'reports_euss' }), value: 'euss' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-dy' }), value: 'sasb-hc-dy' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-di' }), value: 'sasb-hc-di' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-dr' }), value: 'sasb-hc-dr' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-mc' }), value: 'sasb-hc-mc' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-ms' }), value: 'sasb-hc-ms' },
		{ label: intl.formatMessage({ id: 'reports_sasb-hc-bp' }), value: 'sasb-hc-bp' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-ac' }), value: 'sasb-fn-ac' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-cb' }), value: 'sasb-fn-cb' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-cf' }), value: 'sasb-fn-cf' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-in' }), value: 'sasb-fn-in' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-ib' }), value: 'sasb-fn-ib' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-mf' }), value: 'sasb-fn-mf' },
		{ label: intl.formatMessage({ id: 'report_sasb-fn-ex' }), value: 'sasb-fn-ex' },
		{ label: intl.formatMessage({ id: 'report_sasb-cg-aa' }), value: 'sasb-cg-aa' },
		{ label: intl.formatMessage({ id: 'report_sasb-cg-mr' }), value: 'sasb-cg-mr' },
		{ label: intl.formatMessage({ id: 'report_tcfd' }), value: 'tcfd' },
		{ label: intl.formatMessage({ id: 'report_sasb-fb-fr' }), value: 'sasb-fb-fr' },
		{ label: intl.formatMessage({ id: 'report_sasb-if-re' }), value: 'sasb-if-re' },
		{ label: intl.formatMessage({ id: 'report_sfdr' }), value: 'sfdr' },
		{ label: intl.formatMessage({ id: 'report_shift' }), value: 'shift' },
		{ label: intl.formatMessage({ id: 'report_scority' }), value: 'scority' },
		{ label: intl.formatMessage({ id: 'reports_ghg' }), value: 'ghg' },
	];

	const PRODUCT_BASIC_CONFIG = useMemo(() => ({
		community: {
			preferred_languages: COMMUNITY_LANGUAGES,
		},
		atlas: {
			features: {
				dashboard: true,
				approvals: true,
				organization_tree: true,
				data_owners: true,
				targets: false
			},
			reporting_start_date: '2020-01-01',
			preferred_languages: ATLAS_LANGUAGES,
			cycle_date_options: CYCLE_DATE_OPTIONS,
			enabled_reports: ['gri-2021']
		}
	}), [])

	const PRODUCT_OPTIONS = [
		{ keys: ['community'], name: intl.formatMessage({ id: 'product_community' }) },
		{ keys: ['atlas'], name: intl.formatMessage({ id: 'product_atlas' }) },
		{ keys: ['atlas', 'features'], name: intl.formatMessage({ id: 'feature_atlas_materiality' }), config: { materiality: true, groups_of_interest: true } },
		{ keys: ['atlas', 'features'], name: intl.formatMessage({ id: 'feature_atlas_equality' }), config: { equality: true } }
	];

  const [toRemovePreferredLanguages, setToRemovePreferredLanguages] = useState({});

	const dispatch = useDispatch();

	const getValue = useCallback((key) => {
		const currentValue = values[key];
		return typeof currentValue === 'object' ? _.cloneDeep(currentValue) : currentValue;
	}, [values]);

	//const patchedLangsCommunity = useMemo(() => COMMUNITY_LANGUAGES.map(slug => ({name: intl.formatMessage({id: `language_${slug}`}), slug})), [intl]);
	//const patchedLangsAtlas = useMemo(() => ATLAS_LANGUAGES.map(slug => ({name: intl.formatMessage({id: `language_${slug}`}), slug})), [intl]);

	// WORKAROUND: be able to remove languages even if they are not community|atlas
	const patchedLangsCommunity = useMemo(() => {
		const selected = (getValue('product_config')?.community?.preferred_languages || []);
		return Array.from(new Set([
			...COMMUNITY_LANGUAGES,
			...selected,
		])).map(slug => ({ name: intl.formatMessage({ id: `language_${slug}` }), slug }))
	}, [
		intl,
		getValue,
	]);
	const patchedLangsAtlas = useMemo(() => {
		const selected = (getValue('product_config')?.atlas?.preferred_languages || []);
		return Array.from(new Set([
			...ATLAS_LANGUAGES,
			...selected,
		])).map(slug => ({ name: intl.formatMessage({ id: `language_${slug}` }), slug }))
	}, [
		intl,
		getValue,
	]);

	// Consider refactor to recursivity or lodash
	const handleCheckboxChange = useCallback(({ checked, item }) => {
		const currentConfig = getValue('product_config') ?? {};
		if (!checked) {
			item.keys.reduce((acc, key, index) => {
				if (index === item.keys.length - 1) {
					item?.config ?
						Object.keys(item.config).forEach(pcKey => delete acc[key][pcKey]) :
						delete acc[key];
				}
				return acc[key];
			}, currentConfig);
		}
		let defaultValue = item.keys.reduce((acc, key) => acc[key], PRODUCT_BASIC_CONFIG);
		let currentValue = item.keys.reduce((acc, key) => acc[key] ?? defaultValue, currentConfig);
		const value = checked ? (item.keys.reverse()).reduce((acc, key, index) => {
			if (index === 0) {
				acc[key] = { ...defaultValue, ...currentValue, ...item?.config };
			} else {
				acc = { [key]: { ...defaultValue, ...currentValue, ...acc } };
			}
			defaultValue = (item.keys.filter(iKey => iKey !== key)).reduce((acc, key) => acc[key], PRODUCT_BASIC_CONFIG);
			currentValue = (item.keys.filter(iKey => iKey !== key)).reduce((acc, key) => acc[key] ?? defaultValue, currentConfig);
			return acc;
		}, {}) : {};
		handleChange('product_config')({ ...currentConfig, ...value })
	}, [PRODUCT_BASIC_CONFIG, getValue, handleChange]);

	const saveLogos = useCallback((key) => (dataURL) => {
		setLogos({ ...logos, [key]: null });
		handleChange(`${key}`)(dataURL);
	}, [handleChange, logos, setLogos]);

	const {
		data: industries,
	} = useSelector(state => state.industry);

	useEffect(() => {
		dispatch(
			requestIndustries(organization.slug)
		)
	}, [dispatch, organization.slug]);

	const formItemLayout = {
		input: {
			labelCol: { span: 12 },
			wrapperCol: { span: 12 },
		},
		selector: {
			labelCol: { span: 12 },
			wrapperCol: { span: 12 },
		},
		toggle: {
			labelCol: { span: 6 },
			wrapperCol: { span: 6 },
		}
	};

	const organizationLanguageOptions = useMemo(() => {
		const preferredLanguagesAtlas = (
			is_main_org ? values?.product_config : organization?.config
		)?.atlas?.preferred_languages || [];
		return patchedLangsAtlas.filter(
			({ slug }) => preferredLanguagesAtlas.includes(slug)
		);
	}, [
		patchedLangsAtlas,
		organization,
		is_main_org,
		values,
	]);

	const cycleDateOptions = useMemo(() => {
		if (!suborganization?.settings?.atlas) {
			return null;
		}

		return (
			organization?.settings?.atlas?.cycle_date_options ||
			suborganization?.settings?.atlas?.cycle_date_options ||
			null
		);
	}, [
		organization,
		suborganization,
	]);

	const onSelectPreferredLanguages = useCallback((productSlug, currentSelection) => {
		const languagesToRemove = (organization?.config?.[productSlug]?.preferred_languages || []).filter(lang => !currentSelection.includes(lang));
		setToRemovePreferredLanguages((prevState) => ({ ...prevState, [productSlug]: languagesToRemove }));
		handleChange('product_config')({ ...getValue('product_config'), [productSlug]: { ...getValue('product_config')?.[productSlug], preferred_languages: currentSelection.length ? currentSelection : getValue('language') } })
	}, [
		getValue,
		handleChange,
		organization?.config
	]);

	return (industries &&
		<Form
			layout='vertical'
		>
			<Form.Item
				label={intl.formatMessage({ id: 'organization_name' })}
				required
				validateStatus={errors?.name ? 'error' : ''}
				help={errors?.name}
				colon={false}
				{...formItemLayout['input']}
			>
				<CustomInput
					name="name"
					placeholder={intl.formatMessage({ id: 'organization_name' })}
					value={getValue('name')}
					onChange={(e) => handleChangeEvent(e)}
				/>
			</Form.Item>
			<Form.Item
				label={intl.formatMessage({ id: 'organization_legal_name' })}
				colon={false}
				{...formItemLayout['input']}
				validateStatus={errors?.legal_name ? 'error' : ''}
				help={errors?.legal_name}
			>
				<CustomInput
					name="legal_name"
					placeholder={intl.formatMessage({ id: 'organization_legal_name' })}
					value={getValue('legal_name')}
					onChange={(e) => handleChangeEvent(e)}
				/>
			</Form.Item>

			{ is_aplanet_admin &&
			<Form.Item
				label={intl.formatMessage({ id: 'organization_cs_name' })}
				colon={false}
				{...formItemLayout['input']}
				validateStatus={errors?.cs_name ? 'error' : ''}
				help={errors?.cs_name}
			>
				<CustomInput
					name="cs_name"
					placeholder={intl.formatMessage({ id: 'organization_cs_name' })}
					value={getValue('cs_name')}
					onChange={(e) => handleChangeEvent(e)}
				/>
			</Form.Item>
			}
			{ is_aplanet_admin &&
				<Form.Item
				label={intl.formatMessage({ id: 'organization_aplanet_id' })}
				colon={false}
				{...formItemLayout['input']}
				validateStatus={errors?.aplanet_id ? 'error' : ''}
				help={errors?.aplanet_id}
			>
				<CustomInput
					name="aplanet_id"
					placeholder={intl.formatMessage({ id: 'organization_aplanet_id' })}
					value={getValue('aplanet_id')}
					onChange={(e) => handleChangeEvent(e)}
				/>
			</Form.Item>
			}

			{
				!cycleDateOptions || cycleDateOptions.length === 0
					? null
					: (
						<Form.Item
							label={intl.formatMessage({ id: 'organization_profile_cycle_dates' })}
							colon={false}
							{...formItemLayout['selector']}
						>
							<CycleDateInput
								name="cycle_date_options"
								value={getValue('cycle_date_options')}
								undeletable={used_cycle_dates}
								error={errors?.cycle_date_options || null}
								onChange={(e) => handleChange('cycle_date_options')(e)}
							/>
						</Form.Item>
					)
			}

			<Form.Item
				label={intl.formatMessage({ id: 'organization_profile_website' })}
				colon={false}
				{...formItemLayout['input']}
				validateStatus={errors?.url ? 'error' : ''}
				help={errors?.url}
			>
				<CustomInput
					name="url"
					placeholder={intl.formatMessage({ id: 'organization_profile_website' })}
					value={getValue('url')}
					onChange={(e) => handleChangeEvent(e)}
				/>
			</Form.Item>
			<Form.Item
				label={intl.formatMessage({ id: 'industry_and_sector' })}
				required
				colon={false}
				labelCol={{ span: 15 }}
				wrapperCol={{ span: 15 }}
				validateStatus={errors?.industry_id ? 'error' : ''}
				help={errors?.industry_id}
			>
				<NewCustomSelect
					key="industry"
					title={intl.formatMessage({ id: 'industry_and_sector' })}
					onSelect={(value) => handleChange('industry_id')(value)}
					defaultValue={getValue('industry_id')}
					showSearch
					options={(industries || []).map(i => ({ name: `${t.industry[(i.slug || '').toLowerCase()] || t.industry.not_set} (${t.sector[(i.sector || '').toLowerCase()] || t.sector.other})`, id: i.id }))}
					returnValueKey={'id'}
				/>
			</Form.Item>
			<Row className="OrganizationProfile-information-row">
				<Col span={12}>
					<Form.Item
						label={intl.formatMessage({ id: 'organization_profile_logo' })}
						required
						colon={false}
						validateStatus={errors?.logo ? 'error' : ''}
						help={errors?.logo}
					>
						<Logo
							logo={logos.logo}
							setLogo={(value) => setLogos({ logo: value })}
							saveLogo={saveLogos('logo')}
							src={getValue('logo')}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={intl.formatMessage({ id: 'organization_profile_logo_small' })}
						required
						colon={false}
						validateStatus={errors?.logo_small ? 'error' : ''}
						help={errors?.logo_small}
					>
						<Logo
							logo={logos.logo_small}
							setLogo={(value) => setLogos({ logo_small: value })}
							saveLogo={saveLogos('logo_small')}
							src={getValue('logo_small')}
							small
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				label={intl.formatMessage({ id: 'organization_region' })}
				required
				validateStatus={errors?.region ? 'error' : ''}
				help={errors?.region}
				colon={false}
				{...formItemLayout['selector']}
			>
				<NewCustomSelect
					key="region"
					title={intl.formatMessage({ id: 'organization_region' })}
					onSelect={(value) => handleChange('region')(value.toUpperCase())}
					showSearch={false}
					addItems
					addItemsPlaceholder={`${intl.formatMessage({ id: 'other' })}...`}
					options={[
						{ name: `${intl.formatMessage({ id: 'spain' })} (ES)`, slug: 'ES' },
						{ name: `${intl.formatMessage({ id: 'portugal' })} (PT)`, slug: 'PT' },
						{ name: `${intl.formatMessage({ id: 'brazil' })} (BR)`, slug: 'BR' }
					]}
					addItemText={intl.formatMessage({ id: 'add_region' })}
					defaultValue={getValue('region')}
				/>
			</Form.Item>

			<Form.Item
				label={intl.formatMessage({ id: 'organization_address' })}
				required
				colon={false}
				help={errors?.address}
				validateStatus={errors?.address ? 'error' : ''}
			>
				<CustomAddressSearch
					intl={intl}
					organization={suborganization}
					placeholder={intl.formatMessage({ id: 'search_address' })}
					addressValue={getValue('address')}
					latitude={getValue('default_latitude')}
					longitude={getValue('default_longitude')}
					onChangeLocation={({ latitude, longitude, text }) => {
						handleChange('address')(text);
						handleChange('default_longitude')(parseFloat(longitude));
						handleChange('default_latitude')(parseFloat(latitude));
					}}
				/>
			</Form.Item>
			{
				is_aplanet_admin &&
				<Form.Item
					label={intl.formatMessage({ id: 'is_partner' })}
					colon={false}
					{...formItemLayout['toggle']}
					validateStatus={errors?.is_partner ? 'error' : ''}
					help={errors?.is_partner}
				>
					<CustomToggle
						name="is_partner"
						disabled={editting}
						options={[intl.formatMessage({ id: 'yes' }), intl.formatMessage({ id: 'no' })]}
						checked={!!getValue('is_partner')}
						onChange={(value) => handleChange('is_partner')(value)}
					/>
				</Form.Item>
			}
			{is_aplanet_admin &&
				<Form.Item
					label={intl.formatMessage({ id: 'products' })}
					required
					colon={false}
					validateStatus={errors?.product_config ? 'error' : ''}
					help={errors?.product_config}
				>
					{
						PRODUCT_OPTIONS.map((p, i) =>
							<Checkbox
								key={i}
								name={p.name}
								onChange={(e) => handleCheckboxChange({ checked: e.target.checked, item: p })}
								checked={p.keys.reduce((acc, key, index) => {
									if (index === p.keys.length - 1) {
										return p?.config ? Object.keys(p?.config).every(pc => Object.keys(acc[key] ?? {}).includes(pc)) : p?.keys.every(key => Object.keys(acc).includes(key))
									}
									return acc[key] ?? {};
								}, getValue('product_config') ?? {})}
							>
								{p.name}
							</Checkbox>
						)
					}
				</Form.Item>
			}
			<Form.Item
				label={intl.formatMessage({ id: 'organization_language' })}
				required
				colon={false}
				{...formItemLayout['selector']}
				validateStatus={errors?.language ? 'error' : ''}
				help={errors?.language}
			>
				<NewCustomSelect
					key="language"
					title={intl.formatMessage({ id: 'organization_language' })}
					onSelect={(value) => handleChange('language')(value)}
					showSearch={false}
					defaultValue={getValue('language')}
					options={organizationLanguageOptions}
				/>
			</Form.Item>

			{values?.product_config?.community && is_main_org &&
				<>
					<Form.Item
						label={`${intl.formatMessage({ id: 'product_preferred_languages' })} (${intl.formatMessage({ id: 'product_community' })})`}
						colon={false}
						{...formItemLayout['selector']}
						validateStatus={errors?.community_preferred_languages ? 'error' : ''}
						help={errors?.community_preferred_languages}
					>
						<CustomMultiSelect
							title={intl.formatMessage({ id: 'product_preferred_languages' })}
							options={patchedLangsCommunity}
							selected={getValue('language') && getValue('product_config')?.community?.preferred_languages}
							onSelect={(preferred_languages) => onSelectPreferredLanguages(PRODUCT_SLUGS.community, preferred_languages)}
							inModal={true}
							disabledOptions={
								(getValue('product_config')?.community?.preferred_languages || []).includes(getValue('language')) ?
									[getValue('language')] : []
							}
							disabled={!getValue('language')}
						/>
						{used_languages.some(lang => (toRemovePreferredLanguages[PRODUCT_SLUGS.community] || []).includes(lang)) ? (
							<span className='CreateEditOrganizationForm__warning-messsage'>{intl.formatMessage({	 id: 'edit_product_preferred_languages_warning' })}</span>
						) : null}
					</Form.Item>
				</>
			}

			{is_main_org &&
				<Form.Item
					label={`${intl.formatMessage({ id: 'product_preferred_languages' })} (${intl.formatMessage({ id: 'product_atlas' })})`}
					colon={false}
					{...formItemLayout['selector']}
					validateStatus={errors?.atlas_preferred_languages ? 'error' : ''}
					help={errors?.atlas_preferred_languages}
				>
					<CustomMultiSelect
						title={intl.formatMessage({ id: 'product_preferred_languages' })}
						options={patchedLangsAtlas}
						selected={getValue('language') && getValue('product_config')?.atlas?.preferred_languages}
						onSelect={(preferred_languages) => onSelectPreferredLanguages(PRODUCT_SLUGS.atlas, preferred_languages)}
						inModal={true}
						disabledOptions={
							(getValue('product_config')?.atlas?.preferred_languages || []).includes(getValue('language')) ?
								[getValue('language')] : []
						}
						disabled={!getValue('language')}
					/>
					{used_languages.some(lang => (toRemovePreferredLanguages[PRODUCT_SLUGS.atlas] || []).includes(lang)) ? (
						<span className='CreateEditOrganizationForm__warning-messsage'>{intl.formatMessage({	 id: 'edit_product_preferred_languages_warning' })}</span>
					) : null}
				</Form.Item>
			}

			{is_aplanet_admin &&
				<Form.Item
					label={intl.formatMessage({ id: 'organization_status' })}
					required
					validateStatus={errors?.status ? 'error' : ''}
					help={errors?.status}
					colon={false}
					{...formItemLayout['selector']}
				>
					<NewCustomSelect
						key="status"
						title={intl.formatMessage({ id: 'organization_status' })}
						onSelect={(value) => handleChange('status')(value)}
						showSearch={false}
						options={[
							//{name: `${intl.formatMessage({id: 'organization_status_new'})}`, slug : 'new' },
							{ name: `${intl.formatMessage({ id: 'organization_status_onboarding' })}`, slug: 'onboarding' },
							{ name: `${intl.formatMessage({ id: 'organization_status_active' })}`, slug: 'active' },
							{ name: `${intl.formatMessage({ id: 'organization_status_churned' })}`, slug: 'churned' },
							//{name: `${intl.formatMessage({id: 'organization_status_churned'})}`, slug: 'deleted' }
						]}
						defaultValue={`${intl.formatMessage({ id: `organization_status_${getValue('status')}` })}`}
					/>
				</Form.Item>
			}

			{values?.product_config?.atlas && is_aplanet_admin &&
				<>

					<div>{intl.formatMessage({ id: 'product_atlas' })}</div>
					<Form.Item
						label={intl.formatMessage({ id: 'default_standards' })}
						colon={false}
					>
						<Checkbox.Group
							options={ATLAS_ENABLED_REPORTS}
							value={getValue('product_config')?.atlas?.enabled_reports || []}
							onChange={(reports) => {
								handleChange('product_config')({ ...getValue('product_config'), atlas: { ...getValue('product_config')?.atlas, enabled_reports: !reports.length ? ['gri-2021'] : reports } })
							}}
						/>
					</Form.Item>
				</>
			}
		</Form>
	);
};

export default injectIntl(CreateEditOrganizationForm);
